import React, {type FC} from 'react';
import classNames from 'classnames';

import './Cards.scss';

interface Props {
  className?: string;
}

const Cards: FC<Props> = ({children, className}) => {
  return (
    <div className={classNames('cards', className ? {[className]: true} : {})}>{children}</div>
  );
};

export default Cards;
