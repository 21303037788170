import React, {type FC, type PropsWithChildren} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import './Card.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  redirectsTo: string;
  onClick?: () => void;
}

const Card: FC<Props> = (props: PropsWithChildren<Props>) => {
  const {className, onClick} = props;

  const Content = (
    <div className={classNames('card card-large', className ? {[className]: true} : {})}>
      {props.children}
    </div>
  );

  return props.disabled ? (
    <div className="link-unstyled">{Content}</div>
  ) : (
    <Link to={props.redirectsTo} className="link-unstyled" onClick={onClick}>
      {Content}
    </Link>
  );
};

export default Card;
